/* eslint-disable @typescript-eslint/camelcase */
import { BrowserRouter } from 'react-router-dom';
import { Fetcher } from '@clogistic/async';
import { I18nextProvider } from 'react-i18next';
import * as serviceWorker from './serviceWorker';
import config from 'react-global-configuration';
import React from 'react';
import ReactDOM from 'react-dom';

import i18n from './i18n';
import './index.css';

fetch('./config.json')
  .then((response) => response.json())
  .then(async (configData) => {
    config.set(configData);
    const App = await (await import('./App')).default;
    Fetcher.getInstance(configData.api.bff);
    ReactDOM.render(
      <React.StrictMode>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </BrowserRouter>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  })
  // eslint-disable-next-line no-console
  .catch(console.error);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
